import { TextField, Stack } from "@mui/material";
import React, { FC } from "react";
import FunctionBox from "./FunctionBox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { InputDate } from "../../../../../../ui";
import {
  useUpdateLinkModifierMutation,
  SubscriptionPlan,
} from "../../../../../../generated/graphql";
import Paywall from "../../../../../reusable/Paywall";

interface Props {
  id: string;
  scheduleStart: any;
  scheduleEnd: any;
}

const ScheduleLink: FC<Props> = ({ id, scheduleStart, scheduleEnd }) => {
  const [update] = useUpdateLinkModifierMutation();
  const onStartDateChange = (d: string) => {
    update({
      variables: {
        data: {
          id,
          scheduleStart: d,
        },
      },
    });
  };

  const onEndDateChange = (d: string) => {
    update({
      variables: {
        data: {
          id,
          scheduleEnd: d,
        },
      },
    });
  };

  return (
    <FunctionBox
      functionName="Schedule Link"
      description="Add time scheduled publish or create time frame for link visibility."
    >
      <Paywall minPlan={SubscriptionPlan.Plus} outline={30}>
        <Stack
          justifyContent={"space-between"}
          columnGap={4}
          rowGap={1}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <InputDate
            value={scheduleStart}
            onChange={onStartDateChange}
            interval={false}
            fullWidth={true}
            withHours={true}
            label="Start Date & Time"
            InputProps={{
              disableUnderline: true,
              endAdornment: <AccessTimeIcon fontSize="small" />,
            }}
          />
          <InputDate
            value={scheduleEnd}
            onChange={onEndDateChange}
            interval={false}
            fullWidth={true}
            withHours={true}
            label="End Date & Time"
            InputProps={{
              disableUnderline: true,
              endAdornment: <AccessTimeIcon fontSize="small" />,
            }}
          />
        </Stack>
      </Paywall>
    </FunctionBox>
  );
};

export default ScheduleLink;
