import { Box, Stack } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Switch from "../../../../../ui/Button/Switch";
import { Tooltip } from "@mui/material";

interface Props {
  isOpened: boolean;
  isActive: boolean;
  onChangeActiveState: () => void;
  attributes: any;
  listeners: any;
}

const LinkWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  isActive,
  isOpened,
  attributes,
  listeners,
  onChangeActiveState,
}) => {
  return (
    <Stack
      sx={{
        p: "24px",
        backgroundColor: "#fff",
        borderRadius: "25px",
        flexDirection: "row",
        px: "24px",
        pt: "24px",
        pb: isOpened ? 0 : 3,
      }}
    >
      <Stack
        sx={{
          flexDirection: "column",
          alignItems: "center",
          width: "30px",
          justifyContent: "space-between",
          height: "110px",
        }}
      >
        <Switch
          type="green"
          checked={isActive}
          onChange={onChangeActiveState}
          sx={{
            transform: "rotateZ(270deg)",
          }}
        />
        <Tooltip title="Drag to Move">
          <DragIndicatorIcon
            {...attributes}
            {...listeners}
            sx={{
              cursor: "grab",
            }}
          />
        </Tooltip>
      </Stack>
      <Box
        sx={{
          width: "100%",
          pl: "20px",
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default LinkWrapper;
