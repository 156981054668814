import { IconButton, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC } from "react";
import FunctionIcon from "../FunctionIcon";

const Columns = [
  {
    icon: "C1",
    column: 1,
  },
  {
    icon: "C2",
    column: 2,
  },
  {
    icon: "C3",
    column: 3,
  },
];

interface Props {
  onCreateRow: (c: number) => void;
}

const ColumnButtons: FC<Props> = ({ onCreateRow }) => {
  return (
    <Stack>
      <Typography
        fontSize={12}
        fontWeight={500}
        mb={1}
        sx={{
          color: "#04080F",
          textAlign: "center",
        }}
      >
        Add new column
      </Typography>
      <Stack flexDirection={"row"} columnGap={"20px"} justifyContent="center">
        {Columns.map((c) => (
          <FunctionIcon
            onClick={() => onCreateRow(c.column)}
            type={c.icon as any}
            sx={{
              cursor: "pointer",
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ColumnButtons;
