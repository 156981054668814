import React, { Profiler } from "react";
import { DashboardContainerLayout } from "../components/main/DashboardLayout";
import LinkCreator from "../components/page/dashboard/link-creator/index";
import Meta from "../components/reusable/Meta";

const Links = () => {
  return (
    <>
      <Meta title="Links" />
      <LinkCreator />
    </>
  );
};

Links.getLayout = function getLayout(page: any) {
  return <DashboardContainerLayout>{page}</DashboardContainerLayout>;
};

export default Links;
