import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { Stack, Grid } from "@mui/material";
import React, { FC } from "react";
import { LinkModel } from "../../../../../../../generated/graphql";
import { Icon } from "../../../../../../../ui";
import GridImage from "./GridImage";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
interface Props {
  id: string;
  row: LinkModel["children"][0]["row"];
  isOverlay: boolean;
}

function animateLayoutChanges(args: any) {
  const { isSorting, wasSorting, newIndex } = args;

  if (isSorting || wasSorting) {
    return defaultAnimateLayoutChanges(args);
  }

  return false;
}

const GridRow: FC<Props> = ({ row, id, isOverlay = false }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    active,
  } = useSortable({
    id: isOverlay ? "" : id,
    animateLayoutChanges: animateLayoutChanges,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
    // opacity: isDragging ? "1" : "0",
  };

  return row.length > 0 ? (
    <Stack
      ref={isOverlay ? null : setNodeRef}
      sx={{
        width: "100%",
        border: "1px dashed  #CDCECF",
        borderRadius: "13px",
        p: 3,
        flexGrow: 1,
        position: "relative",
        ...style,
      }}
    >
      <DragIndicatorIcon
        {...attributes}
        {...listeners}
        sx={{
          color: "#515151",
          transform: "rotate(90deg) translateY(50%)",
          position: "absolute",
          left: "50%",
          top: "-1px",
          cursor: "grab",
        }}
      />

      <Grid container spacing={2} columns={12}>
        {row.map((item, k) => (
          <Grid item xs={12 / row.length}>
            <GridImage key={item.id} {...item} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  ) : null;
};

export default GridRow;
