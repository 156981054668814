import React, { useEffect, useMemo, useState } from "react";
import {
  LinkType,
  MeDocument,
  SubscriptionPlan,
  useCreateLinkMutation,
  useUpdateLinksOrderMutation,
} from "../../../../generated/graphql";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
  MeasuringStrategy,
  MeasuringConfiguration,
  defaultDropAnimation,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useMixpanel } from "../common/useMixpanel";
import PageLayout from "../../../main/PageLayout";
import { Box, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BioLink from "./components/BioLink";
import { updateLinksList, useBioLinks } from "./state";
import PagePreviewPanel from "../page-generator/preview";
import { Icon, Button } from "../../../../ui";
import FunctionIcon from "./components/functions/FunctionIcon";
import Fab from "../../../../ui/Button/Fab";
import StarIcon from "@mui/icons-material/Star";
import { useModal } from "../../../../ui/Modal";
import EmbedsMarket, { getDefaultPlaceholder } from "./components/EmbedsMarket";
import useSubscriptionPlan from "../common/useSubscriptionPlan";
import PaywallModal from "../../../reusable/modals/PaywallModal";
import SocialBadge from "./components/SocialBadge";

const LinkCreator = () => {
  const { checkPermision, plan } = useSubscriptionPlan();
  const { renderModal, closeModal, openModal, isOpened } = useModal();
  const mixpanel = useMixpanel();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const links = useBioLinks();
  const [createLink, { loading }] = useCreateLinkMutation({
    onCompleted: (d) => {
      isOpened && closeModal();
      mixpanel.track("Created Bio Link", {
        source: "Bio Link Creator",
      });
    },
  });

  const [updateOrder] = useUpdateLinksOrderMutation();

  const [activeId, setActiveId] = useState<any>(null);

  function handleDragEnd(event: any) {
    const { active, over } = event;
    setActiveId(null);
    if (active.id !== over.id) {
      const oldIndex = links.findIndex((i) => i.id === active.id);
      const newIndex = links.findIndex((i) => i.id === over.id);

      const newOrder = arrayMove(links, oldIndex, newIndex).map((f, key) => {
        return {
          ...f,
          order: key + 1,
        };
      });

      updateLinksList(() => newOrder);

      updateOrder({
        variables: {
          data: newOrder.map((p) => ({
            id: p.id,
            order: p.order,
            childRow: p.childRow,
          })),
        },
      });

      setActiveId(null);
    }
  }

  function handleDragStart(event: any) {
    const item = links.find((i) => i.id === event.active.id) || null;
    item && setActiveId(item);
  }

  const createGridLink = (type: LinkType) => {
    if (checkPermision(SubscriptionPlan.Plus)) {
      return onAddLinkClick(type);
    }

    openModal(
      <PaywallModal minPlan={SubscriptionPlan.Plus} onClose={closeModal} />
    );
  };

  const onAddLinkClick = (type: LinkType, embeds?: string) => {
    createLink({
      variables: {
        type: type,
      },
      update: (proxy: any, d: any) => {
        const data = proxy.readQuery({ query: MeDocument });
        proxy.writeQuery({
          query: MeDocument,
          data: {
            ...data,
            me: {
              ...data.me,
              page: {
                ...data.me.page,
                links: [
                  ...data.me.page.links,
                  {
                    ...d.data.createPageLink,
                    isNew: true,
                    urlHolder: embeds || "",
                  },
                ],
              },
            },
          },
        });

        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 10);
      },
    });
  };

  const openEmbedModal = () => {
    openModal(
      <EmbedsMarket onSelectEmbed={onAddLinkClick} close={closeModal} />
    );
  };

  return (
    <>
      {renderModal()}
      <PageLayout panel={<PagePreviewPanel />}>
        <Stack
          flexDirection={["column", "column", "row"]}
          alignItems={["inherit", "inherit", "center"]}
          rowGap={1}
          mb={3}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            columnGap={2}
            sx={{
              justifyContent: {
                xs: "center",
                md: "space-between",
              },
            }}
          >
            <Button
              sx={{
                width: {
                  xs: "57%",
                  md: "auto",
                },
                mt: {
                  xs: 0,
                  md: 0,
                },
              }}
              variant="primary"
              isLoading={loading}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => onAddLinkClick(LinkType.Button)}
            >
              Add New Link
            </Button>
            <Fab
              variant="circular"
              label="Add Dropdown"
              onClick={() => onAddLinkClick(LinkType.DropdownButton)}
            >
              <FunctionIcon display={"flex"} type="Dropdown" />{" "}
            </Fab>
            <Fab
              label="Add Image Grid"
              onClick={() => createGridLink(LinkType.Grid)}
            >
              <FunctionIcon display={"flex"} type="Grid" />
            </Fab>
          </Stack>
          <Button
            variant="outlined"
            onClick={openEmbedModal}
            startIcon={<StarIcon />}
            sx={{
              ml: [0, 0, "auto"],
              backgroundColor: "transparent",
            }}
          >
            Embeds
          </Button>
        </Stack>

        <Stack rowGap={3}>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={links}
              strategy={verticalListSortingStrategy}
            >
              {links.map((link) =>
                link.type === LinkType.SocialIcon &&
                link.title === "Social Badges" ? (
                  <SocialBadge
                    id={link.id}
                    enabled={link.isActive}
                    children={link.children[0].row || []}
                  />
                ) : (
                  <BioLink
                    key={link.id}
                    {...link}
                    isOverlay={false}
                    isChildren={false}
                  />
                )
              )}
            </SortableContext>
          </DndContext>
        </Stack>
      </PageLayout>
    </>
  );
};

export default LinkCreator;
