import { Button, Stack } from "@mui/material";
import React, { FC } from "react";
import client from "../../../../../../config/apollo";
import {
  MeDocument,
  useDeleteLinkMutation,
} from "../../../../../../generated/graphql";
import FunctionBox from "./FunctionBox";

interface Props {
  id: string;
}

const DeleteLink: FC<Props> = ({ id }) => {
  const [deleteLink, { loading }] = useDeleteLinkMutation();

  const onDeleteClick = () => {
    deleteLink({
      variables: {
        data: {
          id: id,
        },
      },
      update: (proxy: any, d: any) => {
        proxy.evict({ id: `LinkModel:${id}` });
      },
    });
  };

  return (
    <FunctionBox
      functionName="Delete Link"
      description="Are you sure you want to delete this link? This action cannot be undone."
    >
      <Stack flexDirection={"row"} columnGap={3}>
        <Button fullWidth={true} variant="outlined" size="small">
          Cancel
        </Button>
        <Button
          fullWidth={true}
          variant="primary"
          size="small"
          onClick={onDeleteClick}
        >
          Delete
        </Button>
      </Stack>
    </FunctionBox>
  );
};

export default DeleteLink;
