import { Button, Stack } from "@mui/material";
import React, { FC, useState } from "react";
import FunctionBox from "../FunctionBox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  LinkFragmentDoc,
  LinkModel,
  LinkType,
  useCreateLinkMutation,
  useUpdateLinksOrderMutation,
} from "../../../../../../../generated/graphql";
import BioLink from "../../BioLink";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { updateLinksList, localLinkUpdate } from "../../../state";

interface Props {
  id: string;
  children: LinkModel["children"];
}

const DropdownList: FC<Props> = ({ id, children: dropdownChildren }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [updateOrder] = useUpdateLinksOrderMutation();

  const [activeId, setActiveId] = useState<any>(null);
  const [createLink, { loading, data }] = useCreateLinkMutation();

  const onCreateButtonClick = () => {
    createLink({
      variables: {
        parent: id,
        type: LinkType.Button,
      },
      update: (proxy, d) => {
        if (!d.data) return;

        const parentLink = proxy.readFragment({
          id: `LinkModel:${id}`,
          fragment: LinkFragmentDoc,
          fragmentName: "Link",
        }) as LinkModel;

        const { children, ...newLink } = d.data.createPageLink;

        proxy.writeFragment({
          id: `LinkModel:${id}`,
          fragment: LinkFragmentDoc,
          fragmentName: "Link",
          data: {
            ...parentLink,
            type: LinkType.DropdownButton,
            children: [
              {
                rowId: "1",
                row: [
                  ...(parentLink.children[0]?.row || []),
                  { ...newLink, isLinkValid: false },
                ],
              },
            ],
          },
        });
      },
    });
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = dropdownChildren[0].row.findIndex(
        (i) => i.id === active.id
      );
      const newIndex = dropdownChildren[0].row.findIndex(
        (i) => i.id === over.id
      );

      const newOrder = arrayMove(
        dropdownChildren[0].row,
        oldIndex,
        newIndex
      ).map((f, key) => {
        return {
          ...f,
          order: key + 1,
        };
      });

      localLinkUpdate({
        id,
        data: {
          children: [
            {
              rowId: "1",
              row: newOrder,
            },
          ],
        },
      });

      updateOrder({
        variables: {
          data: newOrder.map((p) => ({
            id: p.id,
            order: p.order,
            childRow: p.childRow,
          })),
        },
      });

      setActiveId(null);
    }
  }

  function handleDragStart(event: any) {
    const item =
      dropdownChildren[0].row.find((i) => i.id === event.active.id) || null;
    item && setActiveId(item);
  }

  return (
    <FunctionBox
      functionName="Dropdown"
      button={
        <Button
          sx={{ mb: 3, fontSize: 16, fontWeight: 600 }}
          onClick={onCreateButtonClick}
          variant="link"
          size="small"
        >
          + Add New Dropdown Link
        </Button>
      }
    >
      <Stack rowGap={2}>
        {dropdownChildren.length > 0 && (
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={dropdownChildren[0].row}
              strategy={verticalListSortingStrategy}
            >
              {dropdownChildren[0].row.map((link) => (
                <BioLink
                  key={link.id}
                  {...link}
                  isOverlay={false}
                  isChildren={true}
                />
              ))}
            </SortableContext>
          </DndContext>
        )}
      </Stack>
    </FunctionBox>
  );
};

export default DropdownList;
