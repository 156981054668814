import { FormControlLabel, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, useEffect, useState } from "react";
import Switch from "../../../../../../ui/Button/Switch";
import FunctionBox from "./FunctionBox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  SubscriptionPlan,
  useUpdateLinkModifierMutation,
} from "../../../../../../generated/graphql";
import { useBioLinks } from "../../state";
import Paywall from "../../../../../reusable/Paywall";

interface Props {
  id: string;
  leapTime: number;
}

const RedirectLink: FC<Props> = ({ id, leapTime }) => {
  const leapTimeLinks = useBioLinks().filter(
    (l) => l.leapTime > -1 && l.id !== id
  );

  const [activated, setActivated] = useState(leapTime > -1);
  const [_leapTime, setLeapTime] = useState(leapTime);

  const [update] = useUpdateLinkModifierMutation();

  const onChangeLeapTime = (e: any) => {
    setLeapTime(parseInt(e.target.value) || -1);
  };

  const onChangeSwitchState = () => {
    setActivated(!activated);
    update({
      variables: {
        data: {
          id,
          leapTime: !activated ? _leapTime : -1,
        },
      },
    });
  };

  const onBlurInput = () => {
    update({
      variables: {
        data: {
          id,
          leapTime: _leapTime || -1,
        },
      },
    });
  };

  return (
    <FunctionBox
      functionName="Redirect to Link"
      description="        Temporarily send all visitors straight to this link. You can only have
    one active Leap Link at any time."
    >
      <Paywall minPlan={SubscriptionPlan.Plus} outline={30}>
        <Stack
          columnGap={[0, 0, 0, 4]}
          flexDirection={["column", "column", "column", "row"]}
          justifyContent={["flex-start", "space-between"]}
          alignItems="center"
        >
          <FormControlLabel
            sx={{
              mx: 0,
              width: ["100%", "100%", "100%", "50%"],
            }}
            control={
              <Switch
                onChange={onChangeSwitchState}
                disabled={leapTimeLinks.length > 0}
                checked={activated}
              />
            }
            label={
              <Typography ml={1} whiteSpace="nowrap">
                Redirect Visitors
              </Typography>
            }
          />
          <TextField
            label="Set delay (seconds)"
            fullWidth={true}
            value={_leapTime}
            onChange={onChangeLeapTime}
            onBlur={onBlurInput}
            disabled={leapTimeLinks.length > 0}
            InputProps={{
              disableUnderline: true,
              endAdornment: <AccessTimeIcon fontSize="small" />,
            }}
          />
        </Stack>
      </Paywall>
    </FunctionBox>
  );
};

export default RedirectLink;
