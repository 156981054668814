import React, { FC, PropsWithChildren } from "react";
import {
  ButtonProps,
  Button as MaterialButton,
  CircularProgress,
  Box,
  Fab as MuiFab,
  FabProps,
  styled,
  Button,
  Typography,
} from "@mui/material";

interface Props extends FabProps {
  label?: string;
}

const StyledFab = styled(Button)<any>(
  ({ $type, theme, ...props }) => `



        cursor:pointer;
        border-radius: 100%;
        color: #0F75FB;
        background-color: transparent;
        box-shadow:none;
        min-width:54px;
        min-height:54px;
        border:2px solid #0F75FB;
        text-transform:none;
        .t {
            font-family: Poppins;
            display:none;
            margin-left:8px;
        }

        &:hover {
            background-color: #0F75FB;
            color: #fff;
            border-radius: 100px;
            .t {
                display:initial;
            }
        }
`
);

const Fab: FC<PropsWithChildren<Props>> = ({ children, label, ...rest }) => {
  return (
    <StyledFab {...rest}>
      {children}
      <span className="t">{label}</span>
    </StyledFab>
  );
};

export default Fab;
