import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import {
  LinkFragmentDoc,
  LinkModel,
  LinkType,
  MeDocument,
  useCreateLinkMutation,
  useUpdateAppearanceMutation,
} from "../../../../../../../generated/graphql";

import IconInput from "./IconInput";
import InputColor from "../../../../../../../ui/Input/InputColor";
import { useAppearanceLocalUpdate } from "../../../../page-generator/state";

interface Props {
  parentId: string;
  children: LinkModel["children"][0]["row"];
}

const SocialLinksList: FC<Props> = ({ children, parentId }) => {
  const { update, state } = useAppearanceLocalUpdate();
  const [updateAppereance] = useUpdateAppearanceMutation();

  const [createLink, { loading }] = useCreateLinkMutation({
    onCompleted: (d) => {},
  });

  const onAddSocialLink = () => {
    createLink({
      variables: {
        type: LinkType.SocialIcon,
        parent: parentId,
      },
      update: (proxy, d) => {
        if (!d.data) return;

        const parentLink = proxy.readFragment({
          id: `LinkModel:${parentId}`,
          fragment: LinkFragmentDoc,
          fragmentName: "Link",
        }) as LinkModel;

        const { children, ...newLink } = d.data.createPageLink;

        proxy.writeFragment({
          id: `LinkModel:${parentId}`,
          fragment: LinkFragmentDoc,
          fragmentName: "Link",
          data: {
            ...parentLink,
            children: [
              {
                rowId: "1",
                row: [
                  ...(parentLink.children[0]?.row || []),
                  { ...newLink, isLinkValid: true },
                ],
              },
            ],
          },
        });
      },
    });
  };

  const _onChangeIconsColor = (v: string) => {
    update({
      key: "social",
      value: {
        ...state.social,
        iconsColor: v,
      },
    });
  };

  const _updateAppereance = (item: any, id: any) => {
    updateAppereance({
      variables: {
        data: {
          social: {
            iconsColor: state.social?.iconsColor || "#000000",
          },
        },
      },
    });
  };

  const icons: any[] = children || [];

  return (
    <Stack rowGap={1}>
      {icons.slice(0, 4).map((social, key) => {
        return (
          <React.Fragment key={social.id}>
            <IconInput
              key={social.id}
              {...social}
              linkId={social.id}
              platformId={social.title}
            />
          </React.Fragment>
        );
      })}
      <InputColor
        onBlur={_updateAppereance}
        onChange={_onChangeIconsColor}
        label="Icon Color"
        value={state.social?.iconsColor || "#000000"}
        sx={{
          width: "50%",
        }}
      />
      <Stack
        mt={2}
        flexDirection={["column", "column", "column", "row"]}
        justifyContent={"space-between"}
      >
        <Typography fontStyle={"italic"}>
          Need more icons? Add more icons onto contact page!
        </Typography>
        <Button onClick={onAddSocialLink} variant="link" sx={{ p: 0 }}>
          + Add new icon
        </Button>
      </Stack>
      {icons.slice(4, icons.length).map((social, key) => {
        return (
          <React.Fragment key={social.id}>
            <IconInput
              key={social.id}
              {...social}
              linkId={social.id}
              platformId={social.title}
            />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default SocialLinksList;
