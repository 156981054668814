import { MenuItem, Select, SelectProps } from "@mui/material";
import React, { FC } from "react";

type Item = {
  value: any;
  option: string | number;
};

interface Props extends Omit<SelectProps, "variant" | "onChange"> {
  items: Item[];
  onChange: (value: any, id?: any) => void;
}

const IconSelect: FC<Props> = ({ items, onChange, ...props }) => {
  const { sx, id, ...p } = props;

  const _onChange = (e: any, child: any) => {
    onChange(e.target.value, id);
  };

  return (
    <Select
      renderValue={(v: any) => v}
      inputProps={{ disableUnderline: true }}
      onChange={_onChange}
      {...p}
      sx={{
        ...sx,
        "& .MuiInputBase-root": {
          borderRadius: "15px",
        },
        "& .MuiFormLabel-root": {
          fontSize: "14px",
          top: "2px",
        },
        "& .MuiSelect-select": {
          pr: "14px !important",
          textOverflow: "initial",
          "&:focus": {
            backgroundColor: "#F7F7F7",
          },
          "&:hover": {
            backgroundColor: "#F7F7F7",
          },
          backgroundColor: "#F7F7F7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    >
      {items.map(({ value, option }) => (
        <MenuItem value={option}>{value}</MenuItem>
      ))}
    </Select>
  );
};

export default IconSelect;
