import { Button, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useUpdateLinkMutation } from "../../../../../../../generated/graphql";
import { InputGroup } from "../../../../../../../ui";
import { isUrl } from "../../../../../../../utils";

interface Props {
  id: string;
  url: string;
  title: string;
  isActive: boolean;
  onClose: () => void;
}

const GridImageInput: FC<Props> = ({ id, url, isActive, title, onClose }) => {
  const [err, setErr] = useState([true, true]);
  const [update] = useUpdateLinkMutation();
  const oldState = useRef({
    title,
    url,
  });

  const [_title, setTitle] = useState(title);
  const [_url, setUrl] = useState(url);

  const onChangeUrl = (e: any) => {
    setUrl(e.target.value);
    //   localUpdate({ id, key: "url", value: e.target.value });
  };

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value);
    // localUpdate({ id, key: "title", value: e.target.value });
  };

  useEffect(() => {
    const _err = [_title.length < 1, _url.length < 1];

    return setErr(_err);
  }, [_title, _url]);

  const onSave = async () => {
    if (err[0] || err[1]) {
      return;
    }

    if (oldState.current.title !== _title || oldState.current.url !== _url) {
      await update({
        variables: {
          data: {
            id,
            title: _title,
            url: _url,
            isActive: isActive,
          },
        },
      });
      oldState.current.title = _title;
      oldState.current.url = _url;
    }
    onClose();
  };

  return (
    <InputGroup type={["column"]}>
      <TextField
        value={_title}
        label="Image Title"
        onChange={onChangeTitle}
        color={err[0] ? "error" : "primary"}
        helperText={err[0] ? "Title is required" : ""}
      />
      <TextField
        value={_url}
        label="Target URL (www.example.com)"
        onChange={onChangeUrl}
        color={err[1] ? "error" : "primary"}
        helperText={err[1] ? "Url is required" : ""}
      />
      <Button
        sx={{
          width: "fit-content",
          mt: 1,
        }}
        variant="primary"
        size="small"
        onClick={onSave}
      >
        Save
      </Button>
    </InputGroup>
  );
};

export default GridImageInput;
