import { IconButton, Stack } from "@mui/material";
import React, { FC } from "react";
import {
  LinkFragmentDoc,
  LinkModel,
  LinkType,
  useCreateGridLinksMutation,
  useUpdateLinksOrderMutation,
  SubscriptionPlan,
} from "../../../../../../../generated/graphql";
import FunctionBox from "../FunctionBox";
import FunctionIcon from "../FunctionIcon";
import ColumnButtons from "./ColumnButtons";
import GridRow from "./GridRow";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
  MeasuringStrategy,
  MeasuringConfiguration,
  defaultDropAnimation,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { localLinkUpdate } from "../../../state";
import Paywall from "../../../../../../reusable/Paywall";

interface Props {
  id: string;
  children: LinkModel["children"];
}

const LinkImagesGrid: FC<Props> = ({ id, children }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [createNewRow] = useCreateGridLinksMutation();
  const [updateOrder] = useUpdateLinksOrderMutation();

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = children.findIndex((i) => i.rowId === active.id);
      const newIndex = children.findIndex((i) => i.rowId === over.id);

      const newOrder = arrayMove(children, oldIndex, newIndex).map((f, key) => {
        return {
          ...f,
          row: f.row.map((i) => ({
            ...i,
            childRow: key + 1,
          })),
        };
      });

      localLinkUpdate({
        id,
        data: {
          children: newOrder,
        },
      });

      const f = newOrder.map((r) => r.row).flat();

      updateOrder({
        variables: {
          data: f.map((p) => ({
            id: p.id,
            childRow: p.childRow,
            order: p.order,
          })),
        },
      });
    }
  }

  const onCreateNewRow = (rowNumber: number) => {
    createNewRow({
      variables: {
        parent: id,
        count: rowNumber,
      },
      update: (proxy, d) => {
        if (!d.data) return;

        const parentLink = proxy.readFragment({
          id: `LinkModel:${id}`,
          fragment: LinkFragmentDoc,
          fragmentName: "Link",
        }) as LinkModel;

        const newLinks = d.data.createGridLinks;

        proxy.writeFragment({
          id: `LinkModel:${id}`,
          fragment: LinkFragmentDoc,
          fragmentName: "Link",
          data: {
            ...parentLink,
            type: LinkType.Grid,
            children: [
              ...parentLink.children,
              { rowId: `${parentLink.children.length + 1}`, row: newLinks },
            ],
          },
        });
      },
    });
  };

  return (
    <FunctionBox functionName="Image Grid">
      <Stack
        rowGap={1}
        mb={1}
        mt={3}
        sx={{
          maxWidth: "565px",
          mx: "auto",
          width: "100%",
        }}
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          collisionDetection={closestCenter}
          // onDragStart={handleDragStart}
          measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={children.map((c) => c.rowId)}
            strategy={verticalListSortingStrategy}
          >
            {children.map((row) => (
              <GridRow
                key={row.rowId}
                id={row.rowId}
                isOverlay={false}
                row={row.row}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Stack>
      <ColumnButtons onCreateRow={onCreateNewRow} />
    </FunctionBox>
  );
};

export default LinkImagesGrid;
