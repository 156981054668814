import {
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  LinkModel,
  useDeleteBioLinkMutation,
  useDeleteLinkMutation,
} from "../../../../../../../generated/graphql";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useModal } from "../../../../../../../ui/Modal";
import DeleteModal from "../../../../../../reusable/modals/DeleteModal";
import GridModal from "./GridModal";
import FunctionIcon from "../FunctionIcon";
import { Icon } from "../../../../../../../ui";
import ModeIcon from "@mui/icons-material/Mode";
import GridImageInput from "./GridImageInputModal";
import AnimationModal from "./AnimationModal";

interface Props extends LinkModel {}

const GridImage: FC<Props> = ({ id, thumbnail, ...props }) => {
  const match = useMediaQuery("(max-width: 600px)");
  const { openModal, renderModal, closeModal, isOpened } = useModal();

  const [deleteLink, {}] = useDeleteLinkMutation();

  const onDeleteClick = (e: any) => {
    e.stopPropagation();
    openModal(
      <DeleteModal
        onClose={closeModal}
        onConfirm={() => {
          deleteLink({
            variables: {
              data: {
                id,
              },
            },
            update: (proxy: any, d: any) => {
              proxy.evict({ id: `LinkModel:${id}` });
            },
          }).then(() => closeModal());
        }}
        title="Are you sure you want to delete this Link?"
        desc="If you delete Link, you will lose access to it forever. Are you sure you want to do this?"
        confirmText="Yes, delete Link"
      />
    );
  };

  const getElements = () => {
    // @ts-ignore()
    const el = document.getElementById("links-container");

    // @ts-ignore()
    const nav = document.getElementById("nav");

    // @ts-ignore()
    const pr = document.getElementById("panel-container");

    return {
      el,
      nav,
      pr,
    };
  };

  const openGridModal = () => {
    const { el, nav, pr } = getElements();

    // @ts-ignore()
    nav.style.zIndex = 1301;

    if (pr) {
      // @ts-ignore()
      pr.style.zIndex = 1301;
    }

    const { x, y } = el?.getBoundingClientRect() || { x: "50%", y: "50%" };

    openModal(
      <GridModal
        id={id}
        thumbnail={thumbnail || ""}
        {...props}
        onClose={() => {
          if (pr) {
            // @ts-ignore()
            pr.style.zIndex = 1301;
          }

          // @ts-ignore()
          nav.style.zIndex = 1060;
          closeModal();
        }}
      />,
      {
        style: {
          // @ts-ignore()
          top: match ? "100%" : el?.offsetTop ? 190 : 0,
          left: x,
          transform: match ? "translateY(-100%)" : "initial",
          width: el?.clientWidth,
        },
        onCloseCb: () => {
          if (pr) {
            // @ts-ignore()
            pr.style.zIndex = 1301;
          }

          // @ts-ignore()
          nav.style.zIndex = 1060;
        },
      }
    );
  };

  const onAddImageClick = (e: any) => {
    openGridModal();
  };

  const onAnimationClick = () => {
    const { el, nav, pr } = getElements();

    // @ts-ignore()
    nav.style.zIndex = 1301;

    if (pr) {
      // @ts-ignore()
      pr.style.zIndex = 1301;
    }

    const { x, y } = el?.getBoundingClientRect() || { x: "50%", y: "50%" };

    openModal(
      <AnimationModal
        id={id}
        priority={props.priority}
        onClose={() => {
          // @ts-ignore()
          pr.style.zIndex = 3;
          // @ts-ignore()
          nav.style.zIndex = 1060;
          closeModal();
        }}
      />,
      {
        style: {
          // @ts-ignore()
          top: el?.offsetTop + !match ? 190 : 0,
          left: x,
          transform: "initial",
          width: el?.clientWidth,
        },
        onCloseCb: () => {
          if (pr) {
            // @ts-ignore()
            pr.style.zIndex = 1301;
          }

          // @ts-ignore()
          nav.style.zIndex = 1060;
        },
      }
    );
  };

  useEffect(() => {
    if (isOpened) openGridModal();
  }, [thumbnail]);

  return (
    <>
      {renderModal()}
      <Stack
        onClick={thumbnail ? undefined : onAddImageClick}
        sx={{
          backgroundColor: "#F7F7F7",
          aspectRatio: "1",
          width: "100%",
          justifyContent: "center",
          position: "relative",
          cursor: "pointer",
          alignItems: "center",
          outline:
            thumbnail && !props.isLinkValid ? "2px solid #DE3D62" : "0px solid",
          "&:hover": {
            outline: "2px solid",
            outlineColor: "#0F75FB",
          },
          borderRadius: "10px",
          ...(thumbnail && {
            background: `url(${thumbnail}q=80&fm=jpg&crop=entropy&fit=crop&w=600)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }),
        }}
      >
        {!thumbnail && (
          <DeleteOutlineIcon
            onClick={onDeleteClick}
            fontSize="medium"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "error.main",
            }}
          />
        )}
        {!thumbnail && (
          <AddPhotoAlternateIcon
            fontSize="large"
            sx={{
              color: "#515151",
            }}
          />
        )}
        {thumbnail && (
          <Stack
            flexDirection={"row"}
            columnGap={1}
            justifyContent="space-between"
            py={1}
            sx={{
              mb: 1,
              width: "auto",
              background: "#ffffff",
              borderRadius: "50px",
              px: {
                xs: 1,
                sm: 2,
              },
            }}
            mt="auto"
            alignItems="center"
          >
            <Tooltip title={"Edit Link"} placement="top">
              <ModeIcon
                sx={{
                  color: "purple.main",
                  fontSize: {
                    xs: "16px",
                    sm: "23px",
                  },
                }}
                onClick={onAddImageClick}
              />
            </Tooltip>

            <Divider orientation="vertical" />
            <Tooltip title={"Link Animation"} placement="top">
              <FunctionIcon
                type="Animate"
                color={"#000"}
                sx={{
                  transform: "translateY(3px)",
                  fontSize: {
                    xs: "16px",
                    sm: "23px",
                  },
                }}
                onClick={onAnimationClick}
              />
            </Tooltip>
            <Divider orientation="vertical" />
            <Tooltip title={"Delete Link"} placement="top">
              <DeleteOutlineIcon
                onClick={onDeleteClick}
                fontSize="medium"
                sx={{
                  color: "error.main",
                  fontSize: {
                    xs: "16px",
                    sm: "23px",
                  },
                }}
              />
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Typography
        component="div"
        textAlign={"center"}
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: 14,
          mt: "2px",
          alignSelf: "flex-end",
        }}
      >
        {`${props.clicks} Clicks`}
        <Icon type="Clicks" width={"20px"} ml={1} />
      </Typography>
    </>
  );
};

export default GridImage;
