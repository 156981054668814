import { Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { Button, InputGroup } from "../../../ui";
import Modal from "./Modal";

interface Props {
  title: string;
  desc?: string;
  confirmText: string;
  onClose?: () => void;
  onConfirm: () => void;
}

const DeleteModal: FC<Props> = ({
  title,
  confirmText,
  onClose,
  onConfirm,
  desc,
}) => {
  const [saving, setSaving] = useState(false);

  const _onSave = () => {
    setSaving(true);
    onConfirm();
  };

  return (
    <Modal close={onClose} title={title}>
      {desc && (
        <Typography
          maxWidth="420px"
          sx={{
            color: "#04080F",
          }}
          textAlign="center"
        >
          {desc}
        </Typography>
      )}
      <InputGroup
        sx={{
          mt: 3,

          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          columnGap: 3,
        }}
      >
        <Button
          isLoading={saving}
          variant="link"
          color="warning"
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
            },
          }}
          onClick={_onSave}
        >
          {confirmText}
        </Button>
        <Button
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
            },
          }}
          variant="close"
          onClick={onClose}
        >
          Cancel
        </Button>
      </InputGroup>
    </Modal>
  );
};

export default DeleteModal;
