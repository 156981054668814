import React, { FC } from "react";
import { useUpdateLinkModifierMutation } from "../../../../../../../generated/graphql";
import { Dropzone } from "../../../../../../../ui";
import UploadImage from "../../../../../../../ui/Dropzone/UploadImage";
import { useModal } from "../../../../../../../ui/Modal";
import { FileType, uploadFiles } from "../../../../../../../utils";
import AlertModal from "../../../../../../reusable/modals/AlertModal";
import { localLinkUpdate } from "../../../state";

interface Props {
  id: string;
  thumbnail: string;
}

const CustomUploadImage: FC<Props> = ({ id, thumbnail }) => {
  const { closeModal, renderModal, openModal } = useModal();
  const [updateServer] = useUpdateLinkModifierMutation();
  const onLoadProfilImage = async (image: any) => {
    localLinkUpdate({
      id,
      data: {
        thumbnail,
      },
    });
    uploadFiles([
      {
        itemId: id,
        file: image.file,
        type: FileType.LinkThumbnail,
      },
    ]).then((file) => {
      localLinkUpdate({
        id,
        data: {
          thumbnail: file[0].url,
        },
      });
      updateServer({
        variables: {
          data: {
            id,
            thumbnail: file[0].url,
          },
        },
      });
    });
  };
  const onRemoveProfileImage = () => {
    localLinkUpdate({
      id,
      data: {
        thumbnail: null,
      },
    });
    updateServer({
      variables: {
        data: {
          id,
          thumbnail: null,
        },
      },
    });
  };

  const onLoadProfilImageError = (err: any) => {
    openModal(
      <AlertModal
        type="error"
        title="Image size error"
        onClose={closeModal}
        message={err}
      />
    );
  };
  return (
    <Dropzone
      onDropImage={onLoadProfilImage}
      preview={thumbnail || undefined}
      onRemove={onRemoveProfileImage}
      maxSize={2000000}
      onError={onLoadProfilImageError}
    >
      <UploadImage
        onRemove={() => {}}
        image={
          thumbnail ? (thumbnail.includes("unsplash") ? null : thumbnail) : null
        }
        title="Upload custom link image"
        formats={[".png", ".jpg"]}
        maxSize="2MB"
      />
    </Dropzone>
  );
};

export default CustomUploadImage;
