import React, { FC } from "react";
import { useUpdateLinkModifierMutation } from "../../../../../../../generated/graphql";
import Unsplash from "../../../../../../reusable/Unsplash";

interface Props {
  id: string;
  thumbnail: string;
}

const UnsplashImage: FC<Props> = ({ id, thumbnail }) => {
  const [update] = useUpdateLinkModifierMutation();
  const onSelect = (_id: string) => {
    const [url, downloadLocation] = _id.split("*");

    update({
      variables: {
        data: {
          id,
          thumbnail: url,
        },
      },
    });
  };

  return <Unsplash onSelect={onSelect} selected={thumbnail} />;
};

export default UnsplashImage;
