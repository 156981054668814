import { FormControlLabel, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import {
  LinkType,
  SubscriptionPlan,
  useEmbeddedLazyQuery,
  useUpdateLinkModifierMutation,
} from "../../../../../../generated/graphql";
import Switch from "../../../../../../ui/Button/Switch";
import { isUrl } from "../../../../../../utils";
import useSubscriptionPlan from "../../../common/useSubscriptionPlan";
import { localLinkUpdate } from "../../state";
import FunctionBox from "./FunctionBox";

interface Props {
  id: string;
  url: string;
  embeded: boolean;
}

const SettingsLink: FC<Props> = ({ id, embeded, url }) => {
  /*  const [serverUpdate] = useUpdateLinkModifierMutation();
  
    const [embededQuery] = useEmbeddedLazyQuery({
      onCompleted: (d) => {
        if (d.embeded)
          localLinkUpdate({
            id,
            data: {
              embededIframe: d.embeded,
              embeded: true,
              type: LinkType.Embeded,
            },
          });
        serverUpdate({
          variables: {
            data: {
              id,
              embeded: true,
              type: LinkType.Embeded,
            },
          },
        });
      },
    });
  
    const onEnableEmbeded = () => {
      if ((isUrl(url) && !isEmbedable(url) && embeded) || embeded) {
        serverUpdate({
          variables: {
            data: {
              id,
              embeded: false,
              type: LinkType.Button,
            },
          },
        });
        return;
      }
  
      if (isEmbedable(url)) {
        embededQuery({
          variables: {
            url,
          },
        });
      }
    };*/

  return (
    <FunctionBox
      functionName="Settings"
      description="Links from YouTube, Spotify, etc. are automatically showing embedded content. If you want to use a link directly without automatic embeds, disable this option below."
    >
      {/*  <FormControlLabel
        disabled={!isEmbedable(url)}
        control={
          <Switch
            onChange={onEnableEmbeded}
            checked={!!isEmbedable(url) && embeded}
            disabled={!isEmbedable(url)}
          />
        }
        label={
          <Typography ml={2}>
            {!isEmbedable(url)
              ? "Url is not embeddable"
              : " Enable embedded content"}
          </Typography>
        }
      />*/}
    </FunctionBox>
  );
};

export default SettingsLink;
