import { Box, Stack } from "@mui/material";
import React, { FC, useState } from "react";
import {
  useMeQuery,
  useUpdateLinkModifierMutation,
} from "../../../../../../../generated/graphql";
import { Button, Dropzone, Icon } from "../../../../../../../ui";
import { SOCIAL_LINKS } from "../../../../../../../ui/Icon/socialIcons";
import InputColor from "../../../../../../../ui/Input/InputColor";
import { useModal } from "../../../../../../../ui/Modal";
import { instagramCallback } from "../../../../../../reusable/InstagramAuthButton";
import Modal from "../../../../../../reusable/modals/Modal";
import { getLink } from "../../../state";

interface TProps {
  linkId: string;
  onImageDrop: any;
  removeThumbnail: any;
  onLoadProfilImageError: any;
  onSelectIcon: any;
  onClose: any;
}

const ThumbnailModal: FC<TProps> = ({
  onImageDrop,
  removeThumbnail,
  onLoadProfilImageError,
  onSelectIcon,
  linkId,
  onClose,
}) => {
  const { openModal } = useModal();
  const { data: user } = useMeQuery({ fetchPolicy: "cache-only" });
  const instagramIntegration = user?.me.integrations.find(
    (i) => i.provider === "instagram"
  );
  const thumbnail = getLink(linkId)?.thumbnail;
  const [_, id, color] = thumbnail?.includes("customIcon")
    ? thumbnail.split(":")
    : [];
  const [iconColor, setIconColor] = useState(color || "#000000");

  const onSave = () => {
    if (thumbnail?.includes("customIcon")) {
      color !== iconColor && onSelectIcon(`customIcon:${id}:${iconColor}`);
      onClose(`customIcon:${id}:${iconColor}`);
      return;
    }
    onClose();
  };

  const onSelectInstagramPost = (_thumbnail: string) => {
    onSelectIcon(_thumbnail);
  };

  return (
    <Modal
      title="Add Link Thumbnail"
      close={onClose}
      sx={{
        maxWidth: "470px",
      }}
    >
      <Stack flexDirection="row" flexWrap="wrap" marginBottom="-0px !important">
        {SOCIAL_LINKS.map((i) => {
          const Img = <Icon type={i.id as any} width="28" height="28" />;
          return (
            <div
              onClick={() => onSelectIcon(`customIcon:${i.id}:${iconColor}`)}
              style={{
                padding: "0 20px 15px 20px",
                cursor: "pointer",
              }}
            >
              {Img}
            </div>
          );
        })}
      </Stack>
      <Stack
        flexDirection={"row"}
        alignItems="center"
        columnGap={1}
        mb={2}
        width="100%"
      >
        <Box
          sx={{
            p: "13px",
            height: "55px",
            width: "55px",
            borderRadius: "16px",
            backgroundColor: "#F5F5F5",
          }}
        >
          {id && (
            <Icon type={id as any} width="28" height="28" color={iconColor} />
          )}
        </Box>
        <InputColor
          label="Icon Color"
          onChange={(v: string) => setIconColor(v)}
          value={iconColor}
        />
      </Stack>
      {/*instagramIntegration ? (
        <Button
          value="Instagram posts"
          onClick={() => {
            openModal(
              <InstagramPostsListModal
                id={""}
                onThumbnailSelect={onSelectInstagramPost}
              />
            );
          }}
        />
      ) : (
        <Button variant="primary" onClick={instagramCallback}>
          Connect Instagram
        </Button>
      )*/}

      <Stack rowGap={2} width="100%">
        <Dropzone
          onDropImage={onImageDrop}
          onError={onLoadProfilImageError}
          maxSize={1000000}
          preview={thumbnail || undefined}
        >
          <Button fullWidth={true} variant="primary" size="small">
            Upload Image
          </Button>
        </Dropzone>

        <Button variant="outlined" size="small" onClick={onSave}>
          Save
        </Button>
      </Stack>
    </Modal>
  );
};

export default ThumbnailModal;
