import { Box, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { SOCIAL_LINKS } from "../../../../../../../ui/Icon/socialIcons";
import IconSelect from "../../../../../../../ui/Input/IconSelect";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "../../../../../../../ui";
import { localLinkUpdate } from "../../../state";
import {
  useDeleteLinkMutation,
  useUpdateLinkMutation,
} from "../../../../../../../generated/graphql";
import { useModal } from "../../../../../../../ui/Modal";
import DeleteModal from "../../../../../../reusable/modals/DeleteModal";

export interface Props {
  linkId: string;
  platformId: string;
  url: string;
}
const IconInput = React.forwardRef<any, Props>(
  ({ linkId, platformId, url }, ref) => {
    const [deleteLink, { loading }] = useDeleteLinkMutation();
    const { openModal, renderModal, closeModal } = useModal();
    const [updateLink] = useUpdateLinkMutation();
    const [_url, setUrl] = useState(url);

    const createSocialIconsList = (s: any) => {
      const Img =
        s.id === "None" ? null : (
          <Icon type={s.id as any} width="20px" height={"20px"} />
        );
      return {
        value: Img,
        option: s.id,
      };
    };

    const _onChangePlatformUrl = (e: any) => {
      setUrl(e.target.value);
    };

    const _onSelectPlatformIcon = (e: any) => {
      localLinkUpdate({
        id: linkId,
        data: {
          title: e,
        },
      });
      updateLink({
        variables: {
          data: {
            id: linkId,
            url: _url,
            title: e,
            isActive: true,
          },
        },
      });
    };

    const onDeleteClick = () => {
      deleteLink({
        variables: {
          data: {
            id: linkId,
          },
        },
        update: (proxy: any, d: any) => {
          proxy.evict({ id: `LinkModel:${linkId}` });
        },
      });
    };

    const _onRemoveSocial = () => {
      openModal(
        <DeleteModal
          onConfirm={onDeleteClick}
          onClose={closeModal}
          title="Delete Social Link"
          desc="Are you sure you want to delete this link? This action cannot be undone."
          confirmText="Delete"
        />
      );
    };

    const _onBlur = (e: any) => {
      //onBlur(e.target.value, linkId);
      if (url !== _url)
        updateLink({
          variables: {
            data: {
              id: linkId,
              url: _url,
              title: platformId,
              isActive: true,
            },
          },
        });
    };

    const Img =
      !platformId || platformId.includes("None") ? null : (
        <Icon type={platformId as any} width="20px" height={"20px"} />
      );

    return (
      <>
        {renderModal()}
        <Stack
          flexDirection={"row"}
          justifyContent="space-between"
          columnGap={1}
        >
          <IconSelect
            onChange={_onSelectPlatformIcon}
            items={SOCIAL_LINKS.map(createSocialIconsList)}
            value={
              Img ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {Img}
                </Box>
              ) : (
                ""
              )
            }
            sx={{
              width: "56px !important",
              height: "56px",
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
          />
          <Box
            sx={{
              width: "calc(100% - 56px)",
            }}
          >
            <TextField
              label="Platform Profile URL"
              value={_url}
              onBlur={_onBlur}
              onChange={_onChangePlatformUrl}
              InputProps={{
                endAdornment: (
                  <CloseIcon
                    onClick={_onRemoveSocial}
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                ),
                disableUnderline: true,
              }}
            />
          </Box>
        </Stack>
      </>
    );
  }
);

export default IconInput;
