export const SOCIAL_LINKS = [
  {
    id: "Facebook",
    name: "Facebook",
    placeholder: "Facebook Page URL",
  },
  {
    id: "Behance",
    name: "Behance",
    placeholder: "Behance Page URL",
  },
  {
    id: "Twitter",
    name: "Twitter",
    placeholder: "Twitter  URL",
  },
  {
    id: "Instagram",
    name: "Instagram",
    placeholder: "Instagram  URL",
  },
  {
    id: "Youtube",
    name: "Youtube",
    placeholder: "Youtube  URL",
  },
  {
    id: "LinkedIn",
    name: "Linked In",
    placeholder: "Linked In  URL",
  },
  {
    id: "Devianart",
    name: "Devianart",
    placeholder: "Devianart In  URL",
  },
  {
    id: "Ebay",
    name: "Ebay",
    placeholder: "Ebay In  URL",
  },
  {
    id: "Dribble",
    name: "Dribble",
    placeholder: "Dribble In  URL",
  },
  {
    id: "Twitch",
    name: "Twitch",
    placeholder: "Twitch   URL",
  },
  {
    id: "TikTok",
    name: "Tik-Tok",
    placeholder: "TikTok   URL",
  },
  {
    id: "Bitcoin",
    name: "Bitcoin",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Medium",
    name: "Medium",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Etsy",
    name: "Etsy",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Blogger",
    name: "Blogger",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Discord",
    name: "Discord",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Github",
    name: "Github",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Gitlab",
    name: "Gitlab",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "OnlyFans",
    name: "Only Fans",
    placeholder: "OnlyFans   URL",
  },
  {
    id: "Patreon",
    name: "Patreon",
    placeholder: "Patreon   URL",
  },
  {
    id: "AirBnb",
    name: "AirBnb",
    placeholder: "AirBnb Page URL",
  },
  {
    id: "Pinterest",
    name: "Pinterest",
    placeholder: "Pinterest   URL",
  },
  {
    id: "PayPal",
    name: "PayPal",
    placeholder: "PayPal   URL",
  },
  {
    id: "Tripadvisor",
    name: "Tripadvisor",
    placeholder: "Tripadvisor   URL",
  },
  {
    id: "Spotify",
    name: "Spotify",
    placeholder: "Spotify   URL",
  },
  {
    id: "Shopify",
    name: "Shopify",
    placeholder: "Spotify   URL",
  },
  {
    id: "Yelp",
    name: "Yelp",
    placeholder: "Yelp   URL",
  },
  {
    id: "Cart",
    name: "cart",
    placeholder: "E-shop Page URL",
  },
];
