import { Box, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, useState } from "react";
import {
  LinkType,
  SubscriptionPlan,
} from "../../../../../../generated/graphql";
import AnimateLink from "./AnimateLink";
import DropdownList from "./dropdown/DropdownList";
import FunctionIcon from "./FunctionIcon";
import LinkImagesGrid from "./grid/LinkImagesGrid";
import LinkThumbnail from "./thumbnail/LinkThumbnail";
import RedirectLink from "./RedirectLink";
import ScheduleLink from "./ScheduleLink";
import SettingsLink from "./SettingsLink";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteLink from "./DeleteLink";

type LinkFunctions =
  | "leap"
  | "config"
  | "thumbnail"
  | "priority"
  | "schedule"
  | "dropdown"
  | "grid"
  | "map"
  | "stats"
  | "delete"
  | "settings";

export const Functions: {
  id: LinkFunctions;
  Icon: string;
  title: string;
  component: any;
  activeColor: string;
  plan: SubscriptionPlan;
  activeIn: LinkType[];
}[] = [
  {
    id: "leap",
    Icon: "Redirect",
    title: "Leap Link",
    component: RedirectLink,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Plus,
    activeIn: [LinkType.Button],
  },
  {
    id: "thumbnail",
    Icon: "Thumbnail",
    title: "Add Icon",
    component: LinkThumbnail,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Plus,
    activeIn: [LinkType.Button],
  },
  {
    id: "priority",
    Icon: "Animate",
    title: "Animate Link",
    component: AnimateLink,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Plus,
    activeIn: [
      LinkType.Button,
      LinkType.DropdownButton,
      LinkType.Grid,
      LinkType.Embeded,
    ],
  },
  {
    id: "schedule",
    Icon: "Schedule",
    title: "Schedule Link",
    component: ScheduleLink,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Pro,
    activeIn: [
      LinkType.Button,
      LinkType.DropdownButton,
      LinkType.Grid,
      LinkType.Embeded,
    ],
  },
  {
    id: "dropdown",
    Icon: "Dropdown",
    title: "Dropdown",
    component: DropdownList,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Plus,
    activeIn: [LinkType.DropdownButton],
  },
  {
    id: "grid",
    Icon: "Grid",
    title: "Grid",
    component: LinkImagesGrid,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Plus,
    activeIn: [LinkType.Grid],
  },
  {
    id: "config",
    Icon: "Settings",
    title: "Settings",
    component: SettingsLink,
    activeColor: "#0F75FB",
    plan: SubscriptionPlan.Free,
    activeIn: [],
  },
];

const FunctionsRight: {
  id: LinkFunctions;
  Icon: any;
  title: string;
  component: any;
  activeColor: string;
  plan: SubscriptionPlan;
}[] = [
  {
    id: "delete",
    // @ts-ignore
    Icon: <DeleteOutlineIcon color="error" />,
    title: "Delete Link",
    component: DeleteLink,
    activeColor: "#8060FB",
    plan: SubscriptionPlan.Free,
  },
];

interface Props {
  omitFunctions: LinkFunctions[];
  openFunction: (f: any) => void;
  openedFunction: any;
  type: LinkType;
  usedFunctions: any;
}

const FunctionsIconPanel: FC<Props> = ({
  omitFunctions,
  openedFunction,
  openFunction,
  type,
  usedFunctions,
}) => {
  const FilteredFunctions = Functions.filter(
    (f) => !omitFunctions.includes(f.id)
  );

  const onClickFunctionIcon = (f: any) => {
    openFunction(openedFunction?.id === f?.id ? null : f);
  };

  return (
    <Stack flexDirection={"row"} mt={3} justifyContent="space-between">
      <Stack flexDirection={"row"} alignItems="center">
        {FilteredFunctions.filter((f) => f.activeIn.includes(type)).map(
          (f, k) => (
            <Tooltip key={k} title={f.title} placement="top">
              <FunctionIcon
                onClick={() => onClickFunctionIcon(f)}
                type={f.Icon as any}
                sx={{
                  position: "relative",
                  "&::after": {
                    content: '""',
                    width: "100%",
                    height: "10px",
                    left: 0,
                    color: "#fff",
                    backgroundColor: "#fff",
                    position: "absolute",
                    bottom: -8,
                    zIndex: 10,
                    display: openedFunction?.id === f.id ? "initial" : "none",
                  },
                  cursor: "pointer",
                  color:
                    openedFunction?.id === f.id || usedFunctions[f.id]
                      ? "primary.main"
                      : "#515151",
                  px: {
                    xs: "7px",
                    ms: "7px",
                    md: "11px",
                  },
                  pt: "11px",
                  outline:
                    openedFunction?.id === f.id
                      ? "1px solid rgba(81, 81, 81, 0.2)"
                      : "white",
                  borderRadius:
                    openedFunction?.id === f.id
                      ? "17.5px 17.5px 0px 0px"
                      : "none",
                }}
              />
            </Tooltip>
          )
        )}
      </Stack>

      <Stack flexDirection={"row"} alignItems="center" display="flex">
        {FunctionsRight.map((f, k) => (
          <Tooltip key={k} title={f.title} placement="top">
            <Box
              onClick={() => onClickFunctionIcon(f)}
              component="span"
              sx={{
                position: "relative",
                "&::after": {
                  content: '""',
                  width: "100%",
                  height: "10px",
                  left: 0,
                  color: "#fff",
                  backgroundColor: "#fff",
                  position: "absolute",
                  bottom: -8,
                  zIndex: 10,
                  display: openedFunction?.id === f.id ? "initial" : "none",
                },
                cursor: "pointer",
                color: openedFunction?.id === f.id ? "primary.main" : "#515151",
                px: "6px",
                pt: "6px",
                outline:
                  openedFunction?.id === f.id
                    ? "1px solid rgba(81, 81, 81, 0.2)"
                    : "white",
                borderRadius:
                  openedFunction?.id === f.id
                    ? "17.5px 17.5px 0px 0px"
                    : "none",
              }}
            >
              {f.Icon}
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
};

export default FunctionsIconPanel;
