import { Box, Button, Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { useUpdateLinkModifierMutation } from "../../../../../../../generated/graphql";
import {
  borderActive,
  borderSelected,
} from "../../../../../../reusable/content/SelectableItem";
import { localLinkUpdate } from "../../../state";
import { PRIORITY_EFFECTS } from "../AnimateLink";
import FunctionIcon from "../FunctionIcon";

interface Props {
  id: string;
  priority: number;
  onClose: () => void;
}

const AnimationModal: FC<Props> = ({ id, priority, onClose }) => {
  const [p, setP] = useState(priority);

  const [updateServer] = useUpdateLinkModifierMutation();

  const onSelectAnimation = (aid: number) => {
    localLinkUpdate({
      id,
      data: {
        priority: aid,
      },
    });
    setP(aid);
  };

  const onClickSave = () => {
    updateServer({
      variables: {
        data: {
          id,
          priority: p,
        },
      },
    });
    onClose();
  };

  return (
    <Stack rowGap={2}>
      <Stack
        flexDirection="row"
        columnGap={4}
        rowGap={1}
        flexWrap="wrap"
        justifyContent={["center", "center", "flex-start"]}
        sx={{
          px: 0,
          backgroundColor: "#ffffff",
          borderRadius: "15px",
          alignItems: "center",
        }}
      >
        {PRIORITY_EFFECTS.map((pp, k) => (
          <Stack
            sx={{
              width: {
                xs: "calc( (100% / 2)  - 32px)",
                md: 80,
              },
            }}
          >
            <Box
              onClick={() => onSelectAnimation(pp.id)}
              key={k}
              sx={{
                backgroundImage: pp.id === p ? borderSelected : borderActive,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                p: "10px",
                width: "100%",
                cursor: "pointer",
                height: [80, 140, 80],
                borderRadius: "18px",
                color: pp.id === priority ? "#0F75FB" : "#04080F",
              }}
            >
              <FunctionIcon type={pp.icon as any} />
              <Typography
                textAlign={"center"}
                sx={{
                  color: pp.id === priority ? "#0F75FB" : "#04080F",
                  fontWeight: 700,
                  fontSize: "9px",
                }}
              >
                {pp.name}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
      <Button
        variant="primary"
        sx={{
          width: "fit-content",
        }}
        size="small"
        onClick={onClickSave}
      >
        Save
      </Button>
    </Stack>
  );
};

export default AnimationModal;
