import { Stack, Tooltip, Typography } from "@mui/material";
import React, { FC } from "react";
import Switch from "../../../../../ui/Button/Switch";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useSortable } from "@dnd-kit/sortable";
import { animateLayoutChanges } from "./BioLink";
import { CSS } from "@dnd-kit/utilities";
import SocialLinksList from "./functions/badges/SocialLinksList";
import {
  LinkModel,
  LinkType,
  useCreateLinkMutation,
  useUpdateLinkModifierMutation,
  useUpdateLinkMutation,
} from "../../../../../generated/graphql";
import { useMixpanel } from "../../common/useMixpanel";

interface Props {
  id: string;
  enabled: boolean;
  children: any[];
}

const SocialBadge: FC<Props> = ({ id, children, enabled }) => {
  const [changeActiveState] = useUpdateLinkMutation();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    active,
  } = useSortable({
    id: id,
    animateLayoutChanges: animateLayoutChanges,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
  };

  const changeEnabledStatus = () => {
    changeActiveState({
      variables: {
        data: {
          id,
          url: "",
          title: "Social Badges",
          isActive: !enabled,
        },
      },
    });
  };

  return (
    <Stack
      id={"li-" + id}
      ref={setNodeRef}
      sx={{
        ...style,
        p: "24px",
        backgroundColor: "#fff",
        borderRadius: "25px",
        flexDirection: "column",
        px: "24px",
        pt: "24px",
        pb: 3,
        justifyContent: "space-between",
      }}
    >
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        pb={enabled ? 3 : 0}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            columnGap: 3,
          }}
        >
          <Tooltip title="Drag to Move">
            <DragIndicatorIcon
              {...attributes}
              {...listeners}
              sx={{
                cursor: "grab",
              }}
            />
          </Tooltip>
          <Typography>Social Badges</Typography>
        </Stack>
        <Switch checked={enabled} onChange={changeEnabledStatus} />
      </Stack>

      {enabled && <SocialLinksList parentId={id} children={children} />}
    </Stack>
  );
};

export default SocialBadge;
