import React, { FC, useState } from "react";
import { useUpdateLinkModifierMutation } from "../../../../../../../generated/graphql";
import { Icon } from "../../../../../../../ui";
import UploadImage from "../../../../../../../ui/Dropzone/UploadImage";
import { useModal } from "../../../../../../../ui/Modal";
import {
  copyExternalFile,
  FileType,
  uploadFile,
  uploadFiles,
} from "../../../../../../../utils";
import AlertModal from "../../../../../../reusable/modals/AlertModal";
import CropperModal from "../../../../../../reusable/modals/CropperModal";
import { localLinkUpdate } from "../../../state";
import FunctionBox from "../FunctionBox";
import ThumbnailModal from "./ThumbnailModal";

interface Props {
  id: string;
  thumbnail: string | undefined | null;
}

const LinkThumbnail: FC<Props> = ({ id, thumbnail }) => {
  const [updateServer] = useUpdateLinkModifierMutation();
  const { openModal, closeModal, renderModal } = useModal();

  const openThumbnailModal = () => {
    openModal(
      <ThumbnailModal
        onClose={onSave}
        onImageDrop={onImageDrop}
        onSelectIcon={onSelectIcon}
        removeThumbnail={removeThumbnail}
        onLoadProfilImageError={onLoadProfilImageError}
        linkId={id}
      />
    );
  };

  const removeThumbnail = () => {
    updateServer({
      variables: {
        data: {
          id,
          thumbnail: null,
        },
      },
    });
  };

  const onSave = (t: string) => {
    if (t)
      updateServer({
        variables: {
          data: {
            id,
            thumbnail: t,
          },
        },
      });
    closeModal();
  };

  const onImageDrop = (image: any) => {
    openModal(
      <CropperModal
        image={image.preview}
        onClose={closeModal}
        onCrop={onCrop}
      />
    );
  };

  const onSelectIcon = (icon: string) => {
    localLinkUpdate({
      id,
      data: {
        thumbnail: icon,
      },
    });
    // Copy instagram image to joyqr file space
    if (icon.includes("scontent.cdninstagram.com")) {
      copyExternalFile({
        type: FileType.LinkThumbnail,
        itemId: id,
        url: icon,
      });
    }
  };

  const onLoadProfilImageError = (err: any) => {
    openModal(
      <AlertModal
        type="error"
        title="Image size error"
        onClose={closeModal}
        message={err}
      />
    );
  };

  const onCrop = (image: any) => {
    uploadFiles([
      {
        file: image.file,
        type: FileType.LinkThumbnail,
        itemId: id,
      },
    ]).then((f) => {
      updateServer({
        variables: {
          data: {
            id,
            thumbnail: f[0].url,
          },
        },
      });
    });
  };

  const renderThumbnail = () => {
    if (thumbnail?.includes("customIcon")) {
      const [d, icon, color] = thumbnail.split(":");
      const Img = (
        <Icon type={icon as any} width="28" height="28" color={color} />
      );
      return (
        <div
          style={{
            width: "75px",
            height: "75px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Img}
        </div>
      );
    }
    return thumbnail;
  };

  return (
    <>
      {renderModal()}
      <FunctionBox functionName="Add Icon">
        <UploadImage
          onClick={openThumbnailModal}
          onRemove={removeThumbnail}
          image={renderThumbnail() || null}
          title="Upload Link thumbnail"
          formats={[".png", ".jpg"]}
          maxSize="1MB"
        />
      </FunctionBox>
    </>
  );
};

export default LinkThumbnail;
