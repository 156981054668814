import { Box, Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import {
  borderActive,
  borderInactive,
  borderSelected,
} from "../../../../../../reusable/content/SelectableItem";
import Modal from "../../../../../../reusable/modals/Modal";
import Unsplash from "../../../../../../reusable/Unsplash";
import CustomUploadImage from "./CustomUploadImage";
import GridImageInput from "./GridImageInputModal";
import InstagramFeed from "./InstagramFeed";
import UnsplashImage from "./UnsplashImage";

const Tabs = [/*"Instagram Feed",*/ "Image from Unsplash", "Custom Image"];

interface Props {
  id: string;
  thumbnail: string;
  url: string;
  title: string;
  isActive: boolean;
  onClose: () => void;
}

const GridModal: FC<Props> = ({
  id,
  url,
  title,
  isActive,
  thumbnail,
  onClose,
}) => {
  const [imageId, setImageId] = useState(2);
  return !thumbnail ? (
    <Modal title="Add an Image to Grid" close={onClose}>
      <Stack rowGap={3} width="100%">
        <Stack
          flexDirection={"row"}
          columnGap={3}
          sx={{
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            rowGap: 1,
          }}
        >
          {Tabs.map((t, k) => (
            <Box
              onClick={() => setImageId(k)}
              key={k}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: {
                  xs: "100%",
                  sm: "calc(100% / 3)",
                },
                backgroundColor: "background.default",
                height: "115px",
                borderRadius: "18px",
                cursor: "pointer",
                backgroundImage:
                  k === imageId ? borderSelected : borderInactive,
              }}
            >
              <Typography
                fontWeight={600}
                textAlign={"center"}
                color="system.main"
              >
                {t}
              </Typography>
            </Box>
          ))}
        </Stack>
        {/*imageId === 0 && <InstagramFeed id={id} />*/}
        {imageId === 0 && <UnsplashImage id={id} thumbnail={thumbnail} />}
        {imageId === 1 && <CustomUploadImage id={id} thumbnail={thumbnail} />}
      </Stack>
    </Modal>
  ) : (
    <GridImageInput
      id={id}
      url={url}
      isActive={isActive}
      title={title}
      onClose={onClose}
    />
  );
};

export default GridModal;
